import { useConfigurationAtom, userAccessGroupAtom, userAccessGroupResponseAtom } from '_global/Utils/hooks/jotai';
import { IItemAccessGroup } from 'AppContext/UsersAndGroups/AccessGroups/Models/AccessGroupModel';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { Configuration, loadDefaultConfig } from './Configuration';
import SettingsFetcher from './SettingsFetcher';

export function useConfiguration() {
    const [configuration, setConfiguration] = useState<Configuration | undefined>(undefined);
    const setGlobalConfig = useSetAtom(useConfigurationAtom);
    useEffect(() => {
        if (configuration === undefined) {
            loadDefaultConfig()
                .then((config) => {
                    setConfiguration(config);
                    setGlobalConfig(config);
                })
                .catch(console.error);
        }
    }, []);

    return configuration;
}

export function useRemoteAccessGroupConfiguration() {
    const defaultResponse = {
        assets: [],
        workingOPArea: [],
        workingRegions: [],
        devices: []
    };
    const localConfig = useAtomValue(useConfigurationAtom);
    const [userAccessGroupResponse, setUserAccessGroupResponse] = useAtom(userAccessGroupResponseAtom);
    const userAccessGroup = useAtomValue(userAccessGroupAtom);

    const getAssets = useCallback(
        async (accessGroup: IItemAccessGroup) => {
            if (localConfig) {
                const response = await SettingsFetcher.GetUserAccessGroupGrids({
                    configuration: localConfig,
                    accessGroupIds: [accessGroup.id]
                });
                setUserAccessGroupResponse(response ?? defaultResponse);
            }
        },
        [localConfig]
    );

    useEffect(() => {
        if (localConfig && userAccessGroupResponse.assets.length <= 0) {
            void getAssets(userAccessGroup);
        }
    }, [localConfig, userAccessGroup]);

    return userAccessGroupResponse;
}
