import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { Configuration } from 'logic/configuration/Configuration';

const cache = new InMemoryCache();

function httpLink(features: Configuration | undefined): ApolloLink {
    return createHttpLink({
        uri: `${features?.BaseUrl ?? ''}/graphql`
    });
}

const graphQLClient = (features: Configuration | undefined) => {
    return new ApolloClient({
        link: httpLink(features),
        cache: cache
    });
};

export { graphQLClient };
