import { customizationAtom } from '_global/Services/store/customization';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { INavGroupProps } from '../NavGroup';
import NavItem from '../NavItem';

export interface INavCollapseProps {
    menu: INavGroupProps['item'];
    level: number;
}

const NavCollapse = ({ menu, level }: INavCollapseProps) => {
    const theme = useTheme();
    const location = useLocation();
    const customization = useAtomValue(customizationAtom);

    const includesParentPathname = () => menu.children?.some((child) => child.url && location.pathname.includes(child.url)) ?? false;

    const [open, setOpen] = useState(includesParentPathname());
    const [selected, setSelected] = useState<string | null | undefined>(includesParentPathname() ? menu.id : null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    useEffect(() => {
        setOpen(includesParentPathname());
        setSelected(includesParentPathname() ? menu.id : null);
    }, [location]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} level={level + 1} menu={item} />;
            case 'item':
                return <NavItem item={item} key={item.id} level={level + 1} />;
            default:
                return (
                    <Typography align="center" color="error" key={item.id} variant="h6">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon size="20.8px" strokeWidth={1.5} style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            fontSize={level > 0 ? 'inherit' : 'medium'}
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
        />
    );

    return (
        <>
            <ListItemButton
                onClick={handleClick}
                selected={selected === menu.id}
                sx={{
                    borderRadius: `${customization.borderRadius ?? 0}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`
                }}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography color="inherit" sx={{ my: 'auto' }} variant={selected === menu.id ? 'h5' : 'body1'}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography display="block" gutterBottom sx={{ ...theme.typography.caption }} variant="caption">
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp size="16px" stroke={1.5} style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown size="16px" stroke={1.5} style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

export default NavCollapse;
