import { qrCodeStyling } from '_global/Components/base/qr-code/QRCodeStyling';
import QrCodeView from '_global/Components/base/qr-code/QRCodeView';
import { COLORS } from '_global/Utils/Colors';
import { stateToastAtom, useConfigurationAtom } from '_global/Utils/hooks/jotai';
import { IconToNode } from '_global/Utils/IconToNode';
import { faCopy, faLinkSimple } from '@fortawesome/pro-solid-svg-icons';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import MessageType from 'models/enums/MessageTypes';
import { IQrCode, IQrCodeAlternative, IQrCodeResp } from 'models/qr-code/QRCode';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScaleLoader } from 'react-spinners';

const Overview = () => {
    const intl = useIntl();
    const configuration = useAtomValue(useConfigurationAtom);
    const matchesPhone = useMediaQuery('(max-width:600px)');
    const setStateToast = useSetAtom(stateToastAtom);
    const [isLoading, setIsLoading] = useState(true);
    const [changeQr, setChangeQr] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState<string | undefined>(undefined);

    const getDefaultQrCode = async () => {
        if (!configuration) {
            return;
        }
        try {
            const response = await fetch(`${configuration.BaseUrl}/authorization/api/QRCode/get-by-name`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Generic' }),
                headers: { 'Content-Type': 'application/json' }
            });

            return (await response.json()) as IQrCodeResp;
        } catch (e) {
            console.error(e);
        }
    };

    const getAlternativeQrCode = async () => {
        if (!configuration) {
            return;
        }
        try {
            const response = await fetch(`${configuration.BaseUrl}/authorization/api/edgeforceconfigs/url`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });

            if (response) {
                const getUrlQrCode = (await response.json()) as Record<'url', string>;
                if (getUrlQrCode.url) {
                    setQrCodeUrl(getUrlQrCode.url);
                    return getUrlQrCode as IQrCodeAlternative;
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (configuration) {
            if (!changeQr) {
                setIsLoading(true);
                void getDefaultQrCode()
                    .then((data) => {
                        if (data) {
                            delete data.id;
                            const correctObj = { ...data } as unknown as IQrCode;

                            correctObj.initialMapLocation = {
                                Longitude: data.initialMapLocation.longitude,
                                Latitude: data.initialMapLocation.latitude
                            };

                            qrCodeStyling.update({
                                data: JSON.stringify(correctObj),
                                width: matchesPhone ? 280 : 600,
                                height: matchesPhone ? 280 : 614
                            });
                        }
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(true);
                void getAlternativeQrCode()
                    .then((data) => {
                        if (data) {
                            qrCodeStyling.update({
                                data: JSON.stringify(data),
                                width: matchesPhone ? 280 : 584,
                                height: matchesPhone ? 280 : 614
                            });
                            setIsLoading(false);
                        }
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            }
        }
    }, [configuration, changeQr]);

    return (
        <Box sx={{ padding: 2, overflowY: 'scroll', height: '100%', scrollBehavior: 'auto', flexWrap: 'nowrap' }}>
            <Grid alignItems="center" container direction="column" justifyContent="center">
                <Typography sx={{ mt: 2, mb: 3 }} variant={matchesPhone ? 'h4' : 'h2'}>
                    Eneida EdgeForce
                </Typography>
                {isLoading ? (
                    <Box sx={{ minHeight: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ScaleLoader color={COLORS.ENEIDA_ALTERNATIVE_BLUE} height={40} width={20} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 3
                        }}
                    >
                        <QrCodeView qr={qrCodeStyling} />

                        {changeQr && (
                            <Box
                                sx={{
                                    px: '10px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: '100px',
                                    width: '60%',
                                    height: '40px',
                                    background: 'white',
                                    gap: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        overflow: 'hidden',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <IconToNode
                                        definition={faLinkSimple}
                                        size={matchesPhone ? 'sm' : 'lg'}
                                        sx={{ color: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                                    />

                                    <Typography
                                        sx={{
                                            with: '100%',
                                            overflowY: 'scroll',
                                            whiteSpace: 'nowrap',
                                            color: COLORS.ENEIDA_ALTERNATIVE_BLUE,
                                            scrollbarWidth: 'none'
                                        }}
                                    >
                                        {qrCodeUrl}
                                    </Typography>
                                </div>
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        void navigator.clipboard.writeText(qrCodeUrl ?? '');
                                        setStateToast({
                                            message: `${intl.formatMessage({ id: 'global_copied_to_clipboard' })}`,
                                            open: true,
                                            type: MessageType.SUCCESS
                                        });
                                    }}
                                    style={{ cursor: 'pointer', position: 'relative' }}
                                >
                                    <IconToNode
                                        definition={faCopy}
                                        size={matchesPhone ? 'sm' : 'lg'}
                                        sx={{
                                            color: COLORS.ENEIDA_ALTERNATIVE_BLUE
                                        }}
                                    />
                                </div>
                            </Box>
                        )}
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                setChangeQr(!changeQr);
                            }}
                            sx={{
                                color: 'white',
                                background: COLORS.ENEIDA_ALTERNATIVE_BLUE,
                                width: '175px',
                                height: '40px',
                                borderRadius: '12px'
                            }}
                            variant={'contained'}
                        >
                            <FormattedMessage id={!changeQr ? 'global_alternative_qr_code' : 'global_default_qr_code'} />
                        </Button>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

export default Overview;
