import renderMenu from '_global/Components/menu';
import { Typography } from '@mui/material';

import NavGroup from './NavGroup';

const MenuList = () => {
    const navItems = renderMenu()?.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup item={item} key={item.id} />;
            default:
                return (
                    <Typography align="center" color="error" key={item.id} variant="h6">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
