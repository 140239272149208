import './styles/app.scss';
import './styles/DevExpressConfig';

import DeepgridLoader from '_global/Components/base/DeepgridLoader';
import Locales from '_global/Components/base/Locales';
import { ApolloProvider } from '@apollo/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import ChildrenApp from 'ChildrenApp';
import Keycloak from 'keycloak-js';
import { useEffect, useMemo } from 'react';

import { graphQLClient } from './_global/Services/network/endpoint';
import { useConfiguration } from './logic/configuration/Hooks';
import LocalStorageManager from './logic/local/LocalStorageManager';

const App = () => {
    const configuration = useConfiguration();
    const keycloakConfig = useMemo(() => new Keycloak(configuration?.KeycloakConfiguration), [configuration]);

    const graphQLCLient = useMemo(() => {
        return graphQLClient(configuration);
    }, [configuration]);

    useEffect(() => {
        LocalStorageManager.checkIfNeedsToBeCleared();
    }, []);

    return (
        <>
            {configuration && (
                <ReactKeycloakProvider
                    LoadingComponent={<DeepgridLoader open={true} />}
                    authClient={keycloakConfig}
                    initOptions={{ checkLoginIframe: false }}
                >
                    <ApolloProvider client={graphQLCLient}>
                        <Locales>
                            <ChildrenApp />
                        </Locales>
                    </ApolloProvider>
                </ReactKeycloakProvider>
            )}
        </>
    );
};

export default Sentry.withProfiler(App);
