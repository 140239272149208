import RemoteConfiguration, { Visibility } from '../../models/settings/RemoteConfiguration';

export function emptyRemoteConfiguration(): RemoteConfiguration {
    return { language: '', sections: [], selectedAccessGroup: null, simpleSections: [] };
}

export function convertSelectedVisibility(name: string, i18n: string, selected: boolean, visibility: Visibility[]): Visibility[] {
    visibility.push({ name: name, i18n: i18n, value: selected });

    return visibility;
}
