import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';

export enum LoadManagementTab {
    TRANSFORMER_POWER = 'transformer_power',
    FEEDER_POWER = 'feeder_power'
}

interface IDateValue {
    date: string;
    value: number;
}

interface IAsset {
    name: string;
    registration_number: string;
    transformer_uid: string;
}

interface IFeeder {
    feeder_name: string;
    name: string;
    registration_number: string;
    transformer_uid: string;
}

export interface ITransformerPowerTable {
    order: number;
    transformer_ratio: number;
    asset: IAsset;
    active_power_max: IDateValue;
    apparent_power_max: IDateValue;
    i_rms_l1_max: IDateValue;
    i_rms_l2_max: IDateValue;
    i_rms_l3_max: IDateValue;
    power_occupation_max: {
        color: string;
        date: string;
        value: number;
    };
}

export interface IFeederPowerTable {
    order: number;
    asset: IFeeder;
    fuse_rating: number;
    active_power_max: IDateValue;
    apparent_power_max: IDateValue;
    i_rms_l1_max: IDateValue;
    i_rms_l2_max: IDateValue;
    i_rms_l3_max: IDateValue;
    loading_max: {
        color: string;
        date: string;
        value: number;
    };
}

export interface IGenericPowerPage {
    showModal: (responseTitle: string, responseContent: string) => void;
    showToast: (message: string) => void;
}

export function transformerPowerTableToRow(row: ITransformerPowerTable) {
    return {
        order: row.order,
        transformerRatio: row.transformer_ratio,
        asset: { name: row.asset.name, registrationNumber: row.asset.registration_number, transformerUid: row.asset.transformer_uid },
        activePowerMax: { value: row.active_power_max.value, date: row.active_power_max.date },
        apparentPowerMax: { value: row.apparent_power_max.value, date: row.apparent_power_max.date },
        iRmsL1Max: { value: row.i_rms_l1_max.value, date: row.i_rms_l1_max.date },
        iRmsL2Max: { value: row.i_rms_l2_max.value, date: row.i_rms_l2_max.date },
        iRmsL3Max: { value: row.i_rms_l3_max.value, date: row.i_rms_l3_max.date },
        powerOccupationMax: {
            value: row.power_occupation_max.value,
            date: row.power_occupation_max.date,
            color: row.power_occupation_max.color
        }
    };
}

export function feederPowerTableToRow(row: IFeederPowerTable) {
    return {
        order: row.order,
        asset: {
            feederName: row.asset.feeder_name,
            name: row.asset.name,
            registrationNumber: row.asset.registration_number,
            transformerUid: row.asset.transformer_uid
        },
        fuseRating: row.fuse_rating,
        loadingMax: {
            value: row.loading_max.value,
            date: row.loading_max.date,
            color: row.loading_max.color
        },
        iRmsL1Max: { value: row.i_rms_l1_max.value, date: row.i_rms_l1_max.date },
        iRmsL2Max: { value: row.i_rms_l2_max.value, date: row.i_rms_l2_max.date },
        iRmsL3Max: { value: row.i_rms_l3_max.value, date: row.i_rms_l3_max.date },
        activePowerMax: { value: row.active_power_max.value, date: row.active_power_max.date },
        apparentPowerMax: { value: row.apparent_power_max.value, date: row.apparent_power_max.date }
    };
}

export const limits = ['10', '25', '50', '75', '100'];

export interface ITypeColumnExtended extends TypeColWithNameProperty {
    sortable: boolean;
    columnSortAsc?: Record<string, Record<string, unknown>>;
    columnSortDesc?: Record<string, Record<string, unknown>>;
}

export const defaultSortInfo: TypeSingleSortInfo = { name: 'id', dir: 1 };
