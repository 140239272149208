/// <reference types="vite-plugin-svgr/client" />
import EneidaLogo from '_global/Resources/assets/images/eneidaLogo.svg?react';
import { lastUserSettingsAtom } from '_global/Utils/hooks/jotai';
import { ButtonBase } from '@mui/material';
import config from 'config';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const userConfig = useAtomValue(lastUserSettingsAtom);
    return (
        <ButtonBase
            component={Link}
            disableRipple
            to={userConfig?.roles?.[0]?.hasWebAccess ? config.defaultPath : config.defaultCommissionerPath}
        >
            <EneidaLogo />
        </ButtonBase>
    );
};

export default LogoSection;
