import { COLORS } from '_global/Utils/Colors';
import { Backdrop } from '@mui/material';
import { ScaleLoader } from 'react-spinners';
import { LengthType } from 'react-spinners/helpers/props';

type DeepGfridLoaderProps = {
    open: boolean;
    color?: string;
    height?: LengthType;
    width?: LengthType;
};

export default function DeepgridLoader({ open, color, height, width }: DeepGfridLoaderProps) {
    return (
        <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <ScaleLoader color={color ?? COLORS.ENEIDA_ALTERNATIVE_BLUE} height={height ?? 60} width={width ?? 30} />
        </Backdrop>
    );
}
