import { IValidations } from 'models/validation/Validation';

import { CompanyModelKeys, ICompany } from '../../Models/CompanyModel';

export const defaultCompanyValidation = [
    { name: 'country', error: false, message: '' },
    { name: 'enterpriseId', error: false, message: '' },
    { name: 'name', error: false, message: '' }
] as IValidations[];

const isNullOrWhiteSpace = (text: string): boolean => {
    return text === '';
};

const isDifferentFromZero = (text: number): boolean => {
    return text === 0;
};

export const ValidateCompanyValue = (array: IValidations[], id: CompanyModelKeys, value?: string | null | number): IValidations => {
    const validateValues = () => {
        let error = false;

        switch (id) {
            case CompanyModelKeys.name:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return {
                    error: error,
                    message: error ? `companies_name_required` : ''
                };

            case CompanyModelKeys.country:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return { error: error, message: error ? `companies_country_required` : '' };

            case CompanyModelKeys.enterpriseId:
                error = isDifferentFromZero((value as number) ?? 0);
                return { error: error, message: error ? `companies_enterprise_required` : '' };

            default:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return { error: error, message: error ? `companies_${id}_required` : '' };
        }
    };

    return (array[array.findIndex((x) => x.name == id)] = {
        ...array[array.findIndex((x) => x.name == id)],
        error: validateValues().error,
        message: validateValues().message
    });
};

export function ValidateCompany(company: ICompany): IValidations[] {
    for (const [key, value] of Object.entries(company).filter(([key]) => key !== 'id')) {
        ValidateCompanyValue(defaultCompanyValidation, key as CompanyModelKeys, value ? (value as string) : null);
    }
    return defaultCompanyValidation;
}

export function isCompanyValid(company: ICompany): boolean {
    return ValidateCompany(company).filter((x) => x.error).length === 0;
}
