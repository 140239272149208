import { COLORS } from '_global/Utils/Colors';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import MessageType from 'models/enums/MessageTypes';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

type AlertModalProps = {
    message: string;
    type?: MessageType;
    title?: string;
    isOpen: boolean;
    onOk?: () => void;
    onYes?: () => void;
    onNo?: () => void;
    onCancel?: () => void;
};

export default function AlertModal({ message, title, isOpen, type, onOk, onYes, onNo, onCancel }: AlertModalProps) {
    const styleIcon = {
        fontSize: 50
    };
    const styleItem = {
        mt: 2,
        fontSize: 15,
        color: 'white'
    };

    const color = useMemo(() => {
        switch (type) {
            case MessageType.ERROR:
                return COLORS.ENEIDA_RED;
            case MessageType.WARNING:
                return COLORS.ENEIDA_ALTERNATIVE_YELLOW;
            case MessageType.INFO:
                return COLORS.ENEIDA_ALTERNATIVE_BLUE;
            case MessageType.SUCCESS:
                return COLORS.ENEIDA_GREEN;
            default:
                undefined;
        }
    }, [type]);

    const icon = useMemo(() => {
        switch (type) {
            case MessageType.ERROR:
                return <ErrorOutlineIcon sx={styleIcon} />;
            case MessageType.WARNING:
                return <WarningAmberIcon sx={styleIcon} />;
            case MessageType.INFO:
                return <InfoOutlinedIcon sx={styleIcon} />;
            case MessageType.SUCCESS:
                return <CheckCircleOutlineOutlinedIcon sx={styleIcon} />;
            default:
                undefined;
        }
    }, [type]);

    return (
        <Dialog
            PaperProps={{
                style: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent'
                }
            }}
            open={isOpen}
        >
            <Card>
                <Box sx={{ height: 75, bgcolor: color }}>
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Grid container spacing={1}>
                            <Grid item sx={{ color: 'white' }}>
                                {icon}
                            </Grid>
                            {!title && (
                                <Grid item sx={styleItem}>
                                    {type}
                                </Grid>
                            )}
                            <Grid item sx={styleItem}>
                                {title}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                </Box>

                <Box sx={{ bgcolor: 'white' }}>
                    <DialogContent dividers>
                        <Typography gutterBottom>{message}</Typography>
                    </DialogContent>
                </Box>
                <Box sx={{ height: 70, bgcolor: 'white' }}>
                    <DialogActions sx={{ pt: 2 }}>
                        {type !== MessageType.ERROR && type !== MessageType.INFO && (
                            <>
                                {onCancel && (
                                    <Button
                                        onClick={onCancel}
                                        size="small"
                                        sx={{ color: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                                        variant="outlined"
                                    >
                                        <FormattedMessage id={'global_cancel'} />
                                    </Button>
                                )}
                                {onOk !== undefined && (
                                    <Button
                                        onClick={onOk}
                                        size="small"
                                        sx={
                                            onCancel
                                                ? {
                                                      backgroundColor: COLORS.ENEIDA_ALTERNATIVE_BLUE,
                                                      borderColor: COLORS.ENEIDA_ALTERNATIVE_BLUE
                                                  }
                                                : { color: COLORS.ENEIDA_ALTERNATIVE_BLUE }
                                        }
                                        variant={onCancel ? 'contained' : 'outlined'}
                                    >
                                        OK
                                    </Button>
                                )}
                            </>
                        )}
                        {type === MessageType.ERROR && (
                            <>
                                {onOk && (
                                    <Button onClick={onOk} size="small" sx={{ color: COLORS.ENEIDA_ALTERNATIVE_BLUE }} variant="outlined">
                                        OK
                                    </Button>
                                )}
                            </>
                        )}
                        {type === MessageType.INFO && (
                            <>
                                {onOk && !onYes && !onNo && (
                                    <Button
                                        onClick={onOk}
                                        size="small"
                                        sx={
                                            onCancel
                                                ? {
                                                      backgroundColor: COLORS.ENEIDA_ALTERNATIVE_BLUE,
                                                      borderColor: COLORS.ENEIDA_ALTERNATIVE_BLUE
                                                  }
                                                : { color: COLORS.ENEIDA_ALTERNATIVE_BLUE }
                                        }
                                        variant={onCancel ? 'contained' : 'outlined'}
                                    >
                                        OK
                                    </Button>
                                )}
                                {onYes && (
                                    <Button
                                        onClick={onYes}
                                        size="small"
                                        sx={{
                                            backgroundColor: COLORS.ENEIDA_ALTERNATIVE_BLUE,
                                            borderColor: COLORS.ENEIDA_ALTERNATIVE_BLUE
                                        }}
                                        variant={'contained'}
                                    >
                                        <FormattedMessage id={'global_yes'} />
                                    </Button>
                                )}

                                {onNo && (
                                    <Button onClick={onNo} size="small" sx={{ color: COLORS.ENEIDA_ALTERNATIVE_BLUE }} variant={'outlined'}>
                                        <FormattedMessage id={'global_no'} />
                                    </Button>
                                )}
                                {onCancel && (
                                    <Button
                                        onClick={onCancel}
                                        size="small"
                                        sx={{ color: COLORS.ENEIDA_ALTERNATIVE_BLUE }}
                                        variant={'outlined'}
                                    >
                                        <FormattedMessage id={'global_cancel'} />
                                    </Button>
                                )}
                            </>
                        )}
                    </DialogActions>
                </Box>
            </Card>
        </Dialog>
    );
}
