import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LocalStorageManager from '../../../logic/local/LocalStorageManager';

export default function ErrorComponent() {
    const [hasBeenCleared, setHasBeenCleared] = useState(false);

    useEffect(() => {
        if (!hasBeenCleared) {
            LocalStorageManager.clearAllStoredInfo();
            setHasBeenCleared(true);
        }
    }, []);

    return (
        <Box sx={{ height: '100vh', backgroundColor: '#f1f1f1' }}>
            <Box sx={{ paddingY: 15 }}>
                <Card sx={{ maxWidth: 500, margin: '0 auto' }}>
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} variant="h2">
                            <FormattedMessage id="global_needs_refresh_error" />
                        </Typography>

                        <Typography>
                            <FormattedMessage id="global_error_has_been_submitted" />
                        </Typography>
                    </CardContent>

                    <CardActions>
                        <Button onClick={() => window.location.reload()} size="small">
                            <FormattedMessage id="global_refresh_page" />
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Box>
    );
}
