import QRCodeStyling from 'qr-code-styling';

export const qrCodeStyling = new QRCodeStyling({
    width: 580,
    height: 580,
    image: undefined,
    dotsOptions: {
        color: '#000'
    },
    imageOptions: {
        crossOrigin: 'anonymous',
        margin: 20
    },
    qrOptions: {
        errorCorrectionLevel: 'H'
    }
});
