import { MENU_OPEN } from '_global/Services/store/actions';
import { customizationAtom } from '_global/Services/store/customization';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { LinkTarget, NavItemType } from 'models';
import { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect } from 'react';
import { Link } from 'react-router-dom';

export interface INavItemProps {
    item: NavItemType;
    level: number;
}

const NavItem = ({ item, level }: INavItemProps) => {
    const theme = useTheme();
    const [customization, setCustomization] = useAtom(customizationAtom);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item?.icon;
    const itemIcon = item?.icon ? (
        <Icon size="20.8px" stroke={1.5} />
    ) : (
        <FiberManualRecordIcon
            fontSize={level > 0 ? 'inherit' : 'medium'}
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = {
        component: forwardRef(function LinkRef(props, ref) {
            return <Link ref={ref} {...props} target={itemTarget} to={item.url ?? ''} />;
        })
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        setCustomization({ ...customization, type: MENU_OPEN, id });
        matchesSM && setCustomization({ ...customization, type: MENU_OPEN, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id.toLowerCase() === item.id?.toLowerCase());

        if (currentIndex > -1) {
            setCustomization({ ...customization, type: MENU_OPEN, id: item.id });
        }
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => itemHandler(item.id!)}
            selected={customization.id === item.id}
            sx={{
                borderRadius: `${customization.borderRadius ?? 0}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36, color: customization.id === item.id ? '#212121' : undefined }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        color={customization.id === item.id ? undefined : 'inherit'}
                        variant={customization.id === item.id ? 'h5' : 'body1'}
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography display="block" gutterBottom sx={{ ...theme.typography.caption }} variant="caption">
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    color={item.chip.color}
                    label={item.chip.label}
                    size={item.chip.size}
                    variant={item.chip.variant}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
