import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

export enum TabValue {
    FirstPage = 0,
    SecondPage = 1,
    ThirdPage = 2
}

export const hourLimits = [
    [6, 12],
    [12, 18],
    [18, 24],
    [24, 6]
];

export const defaultPowers = [3.45, 4.6, 5.75, 6.9, 10.35, 13.8, 17.25, 20.7];

export type IHourEnergyUse = {
    start: number;
    end: number;
    percent: number;
};

export type INewCostumerEnergyUse = {
    consumption: IHourEnergyUse[];
    production: IHourEnergyUse[];
    asset_id: number;
    feeder_id: number;
    contracted_power: number;
};

export type ICostumerEnergyResponse = {
    best_phase: string;
    current_unbalance_actual: number;
    current_unbalance_after: number;
};

export type ISDSRow = {
    handleChange: (newValue: number, id: string) => void;
    assetId: number;
};

export const marks = [
    {
        value: 33,
        label: 'low'
    },
    {
        value: 66,
        label: 'medium'
    },
    {
        value: 100,
        label: 'high'
    }
];

export const defaultCostumerEnergyResponse = <ICostumerEnergyResponse>{
    best_phase: '',
    current_unbalance_actual: 0,
    current_unbalance_after: 0
};

export function SliderColor(percent: number) {
    return percent === marks[0].value ? '#97CBDC' : percent > marks[0].value && percent <= marks[1].value ? '#018ABD' : '#004581';
}

export function MatchesMD(): boolean {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down('md'));
}
