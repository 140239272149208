import { getDefaultStore } from 'jotai';
import { cloneDeep } from 'lodash';
import { Group } from 'logic/misc/MapExtensions';
import { ReactNode } from 'react';

import { clearSessionTabs, setSessionTabs, useSessionTabs } from '../Services/GenericStorageConnectorFunctional';
import { generalTabs } from './hooks/jotai';

export type Tab = { component: ReactNode; url?: string; tabName?: string };
export type Tabs = Group<string, Tab>;

export function updateTab(action: { name: string; component: ReactNode; url?: string; tabName?: string }) {
    const store = getDefaultStore();

    const internalGroup = cloneDeep(store.get(generalTabs));
    const currentUrl = store.get(generalTabs).get(action.name)?.url;

    internalGroup.set(action.name, {
        component: action.component,
        url: action.url,
        tabName: action.tabName
    });

    const sessionTabs = useSessionTabs();

    if (sessionTabs) {
        const newSessionTabs = sessionTabs.map((tabUrl: string) => {
            return tabUrl === currentUrl ? action.url : tabUrl;
        });

        setSessionTabs(newSessionTabs);
    }

    store.set(generalTabs, internalGroup);
}

export function addTab(action: { name: string; component: ReactNode; url?: string; tabName?: string }) {
    const store = getDefaultStore();
    const newMap = new Group(store.get(generalTabs));

    newMap.set(action.name, {
        component: action.component,
        url: action.url,
        tabName: action.tabName
    });

    const sessionTabs = useSessionTabs();

    if (sessionTabs) {
        const newSessionTabs = [...new Set([...sessionTabs, action.url])];
        setSessionTabs(newSessionTabs);
    } else {
        setSessionTabs([action.url]);
    }

    store.set(generalTabs, newMap);
}

export function removeTab(action: { removeName: string; removeUrl?: string; navigateUrl?: string }) {
    const store = getDefaultStore();
    const newMap = new Group(store.get(generalTabs));

    newMap.delete(action.removeName);

    const sessionTabs = useSessionTabs();

    if (sessionTabs) {
        const newSessionTabs = sessionTabs.filter((tabUrl: string) => tabUrl !== action.removeUrl);
        setSessionTabs(newSessionTabs);
    } else {
        setSessionTabs([]);
    }

    store.set(generalTabs, newMap);
}

export function removeAllTabs() {
    const store = getDefaultStore();

    clearSessionTabs();
    store.set(generalTabs, new Group());
}
