interface IErrorResponse {
    message?: string;
}

const enum HttpStatus {
    NoContent = 204
}

async function getErrorMessage(response: Response): Promise<string> {
    try {
        return (await (response.json() as Promise<IErrorResponse>)).message || response.statusText || 'error';
    } catch {
        return response.statusText || 'error';
    }
}

export const getSessionHeaders = (contentType = 'application/json') => {
    return {
        'Content-type': contentType
    } as HeadersInit;
};

/**
 * @deprecated The method should not be used. Use getDataV2 instead
 */
export async function getData<T>(endpoint: string): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'GET',
        headers: getSessionHeaders()
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return (await response.json()) as Promise<T>;
}

export async function getDataV2<T>(endpoint: string): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'GET',
        headers: getSessionHeaders()
    });
    if (!response.ok) {
        if (response.status === 502) {
            throw new Error('Bad Gateway');
        }

        throw new Error(`${response.status}: ${await getErrorMessage(response)}`);
    }
    return (await response.json()) as Promise<T>;
}

export async function getDataWithBody<TRequest, TResponse>(endpoint: string, body: TRequest): Promise<TResponse> {
    const response = await fetch(endpoint, {
        method: 'GET',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        if (response.status === 502) {
            throw new Error('Bad Gateway');
        }

        throw new Error(`${response.status}: ${await getErrorMessage(response)}`);
    }
    return (await response.json()) as Promise<TResponse>;
}

/**
 * @deprecated The method should not be used. Use postDataV2 instead
 */
export async function postData<T>(endpoint: string, body: T): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error(await getErrorMessage(response));
    }
    return (await response.json()) as Promise<T>;
}

export async function postDataV2<TRequest, TResponse>(endpoint: string, body: TRequest): Promise<TResponse> {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error(await getErrorMessage(response));
    }
    return (await response.json()) as Promise<TResponse>;
}

export async function postFile(endpoint: string, body: FormData) {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {}, // Must not have 'Content-type' header, sent by the form data
        body: body
    });
    if (!response.ok) {
        throw new Error(await getErrorMessage(response));
    }
    return response;
}

export async function postDataDelete<TRequest, TResponse>(endpoint: string, body: TRequest): Promise<TResponse> {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });

    return (await response.json()) as Promise<TResponse>;
}

export async function postDataWithoutResponseBody<T>(endpoint: string, body: T): Promise<void> {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok && response.status !== HttpStatus.NoContent) {
        throw new Error(await getErrorMessage(response));
    }
}

/**
 * @deprecated The method should not be used. Use putDataV2 instead
 */
export async function updateData<T>(endpoint: string, body: T): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'PUT',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return (await response.json()) as Promise<T>;
}

/**
 * @deprecated The method should not be used. Use putDataV2 instead
 */
export async function putData<T>(endpoint: string, body: T): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'PUT',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error(await getErrorMessage(response));
    }
    return (await response.json()) as Promise<T>;
}

export async function putDataV2<TRequest, TResponse>(endpoint: string, body: TRequest): Promise<TResponse> {
    const response = await fetch(endpoint, {
        method: 'PUT',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error(await getErrorMessage(response));
    }
    return (await response.json()) as Promise<TResponse>;
}

export async function patchData<TRequest, TResponse>(endpoint: string, body: TRequest): Promise<TResponse> {
    const response = await fetch(endpoint, {
        method: 'PATCH',
        headers: getSessionHeaders(),
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        throw new Error(await getErrorMessage(response));
    }
    return (await response.json()) as Promise<TResponse>;
}

export async function deleteData<T>(endpoint: string): Promise<T> {
    const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getSessionHeaders()
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return (await response.json()) as Promise<T>;
}
