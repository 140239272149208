import MainCard from '_global/Components/base/cards/MainCard';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ErrorAccessGroupPage = () => {
    return (
        <MainCard className="main-card" content={false} sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <h2>
                    <FormattedMessage id={'global_no_access_group'} />
                </h2>
                <Typography sx={{ textAlign: 'center' }}>
                    <FormattedMessage id={'global_no_access_group_description'} />
                </Typography>
            </Box>
        </MainCard>
    );
};

export default ErrorAccessGroupPage;
