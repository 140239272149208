import { ConTestConfigurationListInstance } from 'queries/ConTestConfiguration';

export interface IConTestConfiguration {
    id: number;
    name: string;
    description: string | null;
    deviceBulkConfigurationId: number | null;
    deviceBatchIds: number[];
    simCardBatchIds: number[];
    createdBy: string | null;
    createdDate: Date | null;
    lastModifiedBy: string | null;
    lastModifiedDate: Date | null;
}

export interface IConTestConfigurationList {
    id: number;
    name: string;
    testedDevices: number;
    remainingDevices: number;
    remainingSimCards: number;
    createdDate: Date | null;
    lastModifiedDate: Date | null;
}

export enum ConTestConfigTabValue {
    INFO,
    DEVICES,
    SIMS,
    RESULTS
}

export enum ConTestConfigSections {
    DEVICES,
    SIMS
}

export function ConTestConfigurationToRow(conTest: ConTestConfigurationListInstance): IConTestConfigurationList {
    return {
        id: Number(conTest.id),
        name: conTest.name,
        testedDevices: Number(conTest.testedDevices),
        remainingDevices: Number(conTest.remainingDevices),
        remainingSimCards: Number(conTest.remainingSimCards),
        createdDate: new Date(String(conTest.createdDate)),
        lastModifiedDate: new Date(String(conTest.lastModifiedDate))
    };
}
