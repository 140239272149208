import Loadable from '_global/Components/base/Loadable';
import { lastUserSettingsAtom } from '_global/Utils/hooks/jotai';
import Overview from 'AppContext/NoAccess/Overview';
import ReportsPageType from 'AppContext/ReportsAndExports/Reports/Models/ReportsPageType';
import { useAtomValue } from 'jotai';
import { lazy, useEffect, useState } from 'react';
import ErrorAccessGroupPage from 'views/Errors/ErrorAccessGroup';

import MainLayout from '../../controllers/layout/MainLayout';
import NoMatch from './NoMatch';

const Dashboard = Loadable(lazy(() => import('views/Dashboard/DashboardPage')));
const Assets = Loadable(lazy(() => import('views/Assets/AssetsPage')));
const ReportsAll = Loadable(
    lazy(() => import('AppContext/ReportsAndExports/Reports/Views/ReportsPage')),
    { reportPageType: ReportsPageType.ALL }
);
const ReportsSystem = Loadable(
    lazy(() => import('AppContext/ReportsAndExports/Reports/Views/ReportsPage')),
    { reportPageType: ReportsPageType.SYSTEM }
);
const ReportsCustom = Loadable(
    lazy(() => import('AppContext/ReportsAndExports/Reports/Views/ReportsPage')),
    { reportPageType: ReportsPageType.CUSTOM }
);
const SIMInformation = Loadable(lazy(() => import('AppContext/ConTest/SimInformation/Views/SIMInformationPage')));
const Regions = Loadable(lazy(() => import('AppContext/Enterprise/Regions/Views/RegionsPage')));
const OpAreas = Loadable(lazy(() => import('AppContext/Enterprise/OperationalAreas/Views/OpAreasPage')));
const Companies = Loadable(lazy(() => import('AppContext/Enterprise/Companies/Views/CompanyPage')));
const Alarms = Loadable(lazy(() => import('AppContext/Alarms/Alarms/Views/AlarmsPage')));
const Devices = Loadable(lazy(() => import('AppContext/Devices/Devices/DevicesPage')));
const ConfigurationProfiles = Loadable(lazy(() => import('AppContext/Devices/ConfigurationProfiles/Views/ConfigurationProfilesPage')));
const Users = Loadable(lazy(() => import('AppContext/UsersAndGroups/Users/Views/UsersPage')));
const UserRoles = Loadable(lazy(() => import('AppContext/UsersAndGroups/UserRoles/Views/UserRolesPage')));
const AccessGroups = Loadable(lazy(() => import('AppContext/UsersAndGroups/AccessGroups/Views/AccessGroupsPage')));
const CableTypes = Loadable(lazy(() => import('AppContext/Assets/CableTypes/Views/CableTypesPage')));
const Contacts = Loadable(lazy(() => import('AppContext/Notifications/Contacts/Views/ContactsPage')));
const AlarmProfiles = Loadable(lazy(() => import('AppContext/Alarms/AlarmProfiles/Views/AlarmProfilesPage')));
// const UnderConstruction = Loadable(lazy(() => import('views/UnderConstruction/UnderConstruction')));
const UserProfile = Loadable(lazy(() => import('views/UserProfile/UserProfile')));
const FirmwareTasks = Loadable(lazy(() => import('views/FirmwareTasks/FirmwareTasksPage')));
const Firmware = Loadable(lazy(() => import('AppContext/Devices/Firmware/Views/FirmwarePage')));
const ConTestConfiguration = Loadable(lazy(() => import('views/ConTestConfiguration/ConTestConfigurationPage')));
const LoadManagement = Loadable(lazy(() => import('AppContext/Apps/LoadManagement/Views/LoadManagementPage')));
const Simulator = Loadable(lazy(() => import('AppContext/Devices/Simulator/Views/SimulatorPage')));
const HVLineDown = Loadable(lazy(() => import('AppContext/Apps/HvLineDown/HVLineDownPage')));
const Subscriptions = Loadable(lazy(() => import('AppContext/Notifications/Subscriptions/SubscriptionsPage')));
const CommercialQuality = Loadable(lazy(() => import('AppContext/Apps/NetworkPowerQuality/CommercialQuality/Views/CommercialQualityPage')));
const Templates = Loadable(lazy(() => import('AppContext/Notifications/Templates/Views/TemplatesPage')));
const ContinuityOfSupply = Loadable(
    lazy(() => import('AppContext/Apps/NetworkPowerQuality/ContinuityOfSupply/Views/ContinuityOfSupplyPage'))
);
const NPQProfiles = Loadable(lazy(() => import('AppContext/Apps/NetworkPowerQuality/Configurations/Profiles/Views/NPQProfilesPage')));
const Scheduler = Loadable(lazy(() => import('AppContext/Apps/NetworkPowerQuality/Configurations/Scheduler/Views/NPQSchedulerPage')));
const ENeuron = Loadable(lazy(() => import('AppContext/Apps/eNeuron/eNeuronPage')));

const getMainElements: Record<string, JSX.Element> = {
    Dashboard: <Dashboard />,
    Assets: <Assets />,
    CableTypes: <CableTypes />,
    Contacts: <Contacts />,
    CommercialQuality: <CommercialQuality />,
    ContinuityOfSupply: <ContinuityOfSupply />,
    Alarms: <Alarms />,
    AlarmProfiles: <AlarmProfiles />,
    ReportsAll: <ReportsAll />,
    ReportsSystem: <ReportsSystem />,
    ReportsCustom: <ReportsCustom />,
    Companies: <Companies />,
    Regions: <Regions />,
    OpAreas: <OpAreas />,
    Devices: <Devices />,
    ConfigurationProfiles: <ConfigurationProfiles />,
    FirmwareTasks: <FirmwareTasks />,
    Firmware: <Firmware />,
    Users: <Users />,
    UserRoles: <UserRoles />,
    AccessGroups: <AccessGroups />,
    SIMInformation: <SIMInformation />,
    ConTestConfiguration: <ConTestConfiguration />,
    Simulator: <Simulator />,
    HvLineDown: <HVLineDown />,
    LoadManagement: <LoadManagement />,
    Subscriptions: <Subscriptions />,
    Templates: <Templates />,
    Profiles: <NPQProfiles />,
    Scheduler: <Scheduler />,
    ENeuronScheduler: <ENeuron />
};

function mainRoutes() {
    const config = useAtomValue(lastUserSettingsAtom);

    const [mainPaths, setMainPaths] = useState<{
        path: string;
        element: JSX.Element;
        children: {
            path: string;
            element: JSX.Element;
        }[];
    } | null>(null);

    useEffect(() => {
        if (config) {
            let routes = [];

            if (config.roles?.[0]?.hasWebAccess) {
                routes = config?.sidebar?.routes?.map((route) => {
                    const element = getMainElements[route.element];
                    return { path: route.path, element };
                });

                routes.push({
                    path: '/user/profile',
                    element: <UserProfile />
                });
                routes.push({
                    path: '/error/accessgroup',
                    element: <ErrorAccessGroupPage />
                });
            } else {
                routes.push({
                    path: '/overview',
                    element: <Overview />
                });
            }

            routes.push({
                path: '*',
                element: <NoMatch />
            });

            const allRoutes = {
                path: '/',
                element: <MainLayout />,
                children: routes
            };
            setMainPaths(allRoutes);
        }
    }, [config, config?.roles?.[0]?.hasWebAccess]);

    return mainPaths;
}

export default mainRoutes;
