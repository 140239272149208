import { TypeColumn, TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types';
import { ITypeColumnExtended } from 'AppContext/Assets/Assets/Views/Components/Grid/AssetsGrid';
import { atom } from 'jotai';
import { SortInfoType } from 'models/sortInfo';
import { SetStateAction } from 'react';
import { Location } from 'react-router-dom';

export type GridState = { skip?: number; sortInfo?: TypeSingleSortInfo; columnOrder?: Array<string>; limit?: number };
export type GridPagination = { key: string; url: string; grid: GridState };
export type IGridsPaginationState = Array<GridPagination>;

const gridsPaginationInitialState: IGridsPaginationState = [];
export const gridsPaginationAtom = atom<IGridsPaginationState>(gridsPaginationInitialState);
type SetAtom<Args extends any[], Result> = (...args: Args) => Result;
interface IGridsPaginationUpdateGrid {
    gridsPagination: IGridsPaginationState;
    gridName: string;
    columns: TypeColumn[];
    setGridSkip: (value: SetStateAction<number>) => void;
    setGridSortInfo: (value: SetStateAction<TypeSingleSortInfo | undefined>) => void;
    setGridColumnOrder: (value: SetStateAction<string[] | undefined>) => void;
    setGridLimit: (value: SetStateAction<number>) => void;
    location: Location;
    sortInfo?: SortInfoType;
    isFirmware?: boolean;
}

export const setGridsPagination = ({
    newGridPagination,
    setGridPagination
}: {
    newGridPagination: Array<GridPagination>;
    setGridPagination: SetAtom<[SetStateAction<IGridsPaginationState>], void>;
}) => {
    setGridPagination((state) => ({ ...state, gridsPagination: newGridPagination }));
};

export const addGridPagination = ({
    newPagination,
    gridsPagination,
    setGridPagination
}: {
    newPagination: GridPagination;
    gridsPagination: IGridsPaginationState;
    setGridPagination: SetAtom<[SetStateAction<IGridsPaginationState>], void>;
}) => {
    const newSessionGridsPagination = [...gridsPagination];
    const findGridPagination = newSessionGridsPagination.findIndex(
        (gridPagination) => gridPagination?.url === newPagination.url && gridPagination?.key === newPagination?.key
    );
    if (findGridPagination === -1) {
        newSessionGridsPagination.push(newPagination);
        setGridPagination(newSessionGridsPagination);
    } else {
        newSessionGridsPagination[findGridPagination].grid = newPagination.grid;
        setGridPagination(newSessionGridsPagination);
    }
};

export const removeGridPagination = ({
    url,
    key,
    gridsPagination,
    setGridPagination
}: {
    url: string;
    key?: string;
    gridsPagination: IGridsPaginationState;
    setGridPagination: SetAtom<[SetStateAction<IGridsPaginationState>], void>;
}) => {
    const newSessionGridsPagination = [...gridsPagination];
    if (key) {
        newSessionGridsPagination.forEach((gridPagination) => {
            if (gridPagination?.url === url && gridPagination?.key === key) {
                gridPagination.grid = { skip: 0, sortInfo: { dir: 0, name: '', id: '' }, columnOrder: [], limit: 10 };
            }
        });
        setGridPagination(newSessionGridsPagination);
    } else {
        newSessionGridsPagination.forEach((gridPagination) => {
            if (gridPagination?.url === url) {
                gridPagination.grid = { skip: 0, sortInfo: { dir: 0, name: '', id: '' }, columnOrder: [], limit: 10 };
            }
        });
        setGridPagination(newSessionGridsPagination);
    }
};

export const removeAllGrids = ({ setGridPagination }: { setGridPagination: SetAtom<[SetStateAction<IGridsPaginationState>], void> }) => {
    setGridPagination(gridsPaginationInitialState);
};

export default function gridsPaginationUpdateGrid({
    gridsPagination,
    gridName,
    columns,
    setGridSkip,
    setGridSortInfo,
    setGridColumnOrder,
    setGridLimit,
    location,
    sortInfo,
    isFirmware
}: IGridsPaginationUpdateGrid) {
    if (gridsPagination?.length > 0 && location.pathname) {
        gridsPagination.forEach((gridPagination: GridPagination) => {
            if (gridPagination.url === location.pathname && gridPagination.key === gridName) {
                if (gridPagination.grid?.sortInfo) {
                    if (sortInfo) {
                        const section = columns.find((section) => section.name === gridPagination.grid.sortInfo?.id);
                        if ((section as ITypeColumnExtended)?.columnSortAsc && (section as ITypeColumnExtended)?.columnSortDesc) {
                            if (isFirmware) {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string,
                                    (section as ITypeColumnExtended).columnSortAsc,
                                    (section as ITypeColumnExtended).columnSortDesc,
                                    (gridPagination.grid.sortInfo.id as string) === 'firmware' ? 'name' : undefined
                                );
                            } else {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string,
                                    (section as ITypeColumnExtended).columnSortAsc,
                                    (section as ITypeColumnExtended).columnSortDesc
                                );
                            }
                        } else {
                            if (isFirmware) {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string,
                                    undefined,
                                    undefined,
                                    (gridPagination.grid.sortInfo.id as string) === 'firmware' ? 'name' : undefined
                                );
                            } else {
                                sortInfo(
                                    gridPagination.grid.sortInfo.id as string,
                                    gridPagination.grid.sortInfo.dir as number,
                                    gridPagination.grid.sortInfo.type as string
                                );
                            }
                        }
                    }
                }
                setGridSkip(gridPagination.grid?.skip ?? 0);
                if (gridPagination.grid?.sortInfo !== undefined) {
                    setGridSortInfo(gridPagination.grid?.sortInfo);
                }
                setGridLimit(gridPagination.grid?.limit ?? 0);
            }
        });
    } else {
        setGridSkip(0);
        setGridSortInfo({ dir: 0, name: '', id: '' });
        setGridColumnOrder(columns.map((column) => column.name) as string[]);
        setGridLimit(10);
    }
}
