import { supportedLocales } from '_global/Components/base/Locales';
import AlertModal from '_global/Components/base/modals/AlertModal';
import { availableLanguages } from '_global/Utils/GetCurrentLocale';
import { lastUserSettingsAtom, stateToastAtom, userLanguageAtom } from '_global/Utils/hooks/jotai';
import { getDefaultLanguage } from '_global/Utils/language';
import { LanguagesEnum } from '_global/Utils/LocaleLanguageConverter';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom, useSetAtom } from 'jotai';
import LocalStorageManager from 'logic/local/LocalStorageManager';
import MessageType from 'models/enums/MessageTypes';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const LanguageGroupSection = () => {
    const theme = useTheme();
    const intl = useIntl();
    const [userConfig, setUserConfig] = useAtom(lastUserSettingsAtom);
    const [{ responseContent, responseTitle, openModal, type }, setStateModal] = useState({
        openModal: false,
        responseContent: '',
        responseTitle: '',
        type: MessageType.INFO
    });
    const setStateToast = useSetAtom(stateToastAtom);
    const [langSelected, setLangSelected] = useState<string>('');
    const [userLanguage, setUserLanguage] = useAtom(userLanguageAtom);

    const showToast = (toastMessage: string) => {
        setStateToast({
            message: `${intl.formatMessage({ id: toastMessage })}`,
            open: true,
            type: MessageType.SUCCESS
        });
    };

    const setUserSettings = (lang: string) => {
        if (userConfig) {
            setUserConfig({
                ...userConfig,
                userSettings: {
                    ...userConfig?.userSettings,
                    language: lang
                }
            });
        }
    };

    useEffect(() => {
        if (userConfig?.userSettings?.language === undefined || userConfig?.userSettings?.language === '') {
            const browserLanguage = getDefaultLanguage();
            setUserSettings(supportedLocales.includes(browserLanguage) ? browserLanguage : 'en-GB');
        }

        if (userConfig?.userSettings?.language && userConfig?.userSettings?.language !== '') {
            setUserLanguage(supportedLocales.includes(userConfig.userSettings.language) ? userConfig.userSettings.language : 'en-GB');
            if (!supportedLocales.includes(userConfig.userSettings.language)) {
                const browserLanguage = getDefaultLanguage();
                setUserSettings(supportedLocales.includes(browserLanguage) ? browserLanguage : 'en-GB');
            }
        }
    }, [userConfig?.userSettings]);

    const handleYesModal = useCallback(() => {
        setStateModal((prev) => ({ ...prev, openModal: false }));
        if (langSelected) {
            void setUserSettings(langSelected);
            setUserLanguage(langSelected);
        }
        LocalStorageManager.clearAllStoredInfo();
        showToast('global_user_session_restarted');
    }, [langSelected]);

    const handleNoModal = () => {
        setStateModal((prev) => ({ ...prev, openModal: false }));
    };

    const handleChange = () => {
        setStateModal({
            openModal: true,
            responseTitle: intl.formatMessage({ id: 'global_response_title_change_language' }),
            responseContent: intl.formatMessage({ id: 'global_response_body_user_restarted' }),
            type: MessageType.INFO
        });
    };

    return (
        <Box>
            <AlertModal
                isOpen={openModal}
                message={responseContent}
                onNo={handleNoModal}
                onYes={handleYesModal}
                title={responseTitle}
                type={type}
            />

            <Autocomplete
                disableClearable
                getOptionLabel={(option) => {
                    const specialCases = option.includes('pt') ? 'pt-PT' : option.includes('de') ? 'de-DE' : option;
                    switch (specialCases) {
                        case 'pt-PT':
                            return `${intl.formatMessage({ id: 'languages_portuguese' })}`;
                        case 'de-DE':
                            return `${intl.formatMessage({ id: 'languages_german' })}`;
                        case 'en-GB':
                            return `${intl.formatMessage({ id: 'languages_english' })} (GB)`;
                        default:
                            return `${intl.formatMessage({ id: 'languages_english' })} ${option.split('-')[1] ? `(${option.split('-')[1]})` : ''}`;
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    if (value === '') {
                        return true;
                    }
                    return option === value;
                }}
                onChange={(event, newValue) => {
                    setLangSelected(newValue);
                    handleChange();
                }}
                options={availableLanguages ?? []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                fontWeight: 800
                            }
                        }}
                        label={intl.formatMessage({ id: 'languages_title' })}
                        placeholder={intl.formatMessage({ id: 'global_search_to_add' })}
                        sx={{
                            '& label.Mui-focused': {
                                color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                                },
                                '.MuiInputBase-input': {
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                                }
                            }
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    switch (option) {
                        case LanguagesEnum.pt_PT:
                            return (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 1, flexShrink: 0, height: '17px', width: '20px', borderRadius: 0 } }}
                                    {...props}
                                >
                                    <img
                                        alt=""
                                        loading="lazy"
                                        src={`https://flagcdn.com/w20/pt.png`}
                                        srcSet={`https://flagcdn.com/w40/pt.png 2x`}
                                    />
                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <Typography>{intl.formatMessage({ id: 'languages_portuguese' })}</Typography>
                                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                                            {intl.formatMessage({ id: `languages_${option}` })}
                                        </Typography>
                                    </span>
                                </Box>
                            );
                        case LanguagesEnum.en_NZ:
                            return (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 1, flexShrink: 0, height: '17px', width: '20px', borderRadius: 0 } }}
                                    {...props}
                                >
                                    <img
                                        alt=""
                                        loading="lazy"
                                        src={`https://flagcdn.com/w20/nz.png`}
                                        srcSet={`https://flagcdn.com/w40/nz.png 2x`}
                                    />
                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <Typography>{intl.formatMessage({ id: 'languages_english' })}</Typography>
                                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                                            {intl.formatMessage({ id: `languages_${option}` })}
                                        </Typography>
                                    </span>
                                </Box>
                            );
                        case LanguagesEnum.en_AU:
                            return (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 1, flexShrink: 0, height: '17px', width: '20px', borderRadius: 0 } }}
                                    {...props}
                                >
                                    <img
                                        alt=""
                                        loading="lazy"
                                        src={`https://flagcdn.com/w20/au.png`}
                                        srcSet={`https://flagcdn.com/w40/au.png 2x`}
                                    />
                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <Typography>{intl.formatMessage({ id: 'languages_english' })}</Typography>
                                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                                            {intl.formatMessage({ id: `languages_${option}` })}
                                        </Typography>
                                    </span>
                                </Box>
                            );
                        case LanguagesEnum.en_GB:
                            return (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 1, flexShrink: 0, height: '17px', width: '20px', borderRadius: 0 } }}
                                    {...props}
                                >
                                    <img
                                        alt=""
                                        loading="lazy"
                                        src={`https://flagcdn.com/w20/gb.png`}
                                        srcSet={`https://flagcdn.com/w40/gb.png 2x`}
                                    />
                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <Typography>{intl.formatMessage({ id: 'languages_english' })}</Typography>
                                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                                            {intl.formatMessage({ id: `languages_${option}` })}
                                        </Typography>
                                    </span>
                                </Box>
                            );
                        case LanguagesEnum.de_DE:
                            return (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 1, flexShrink: 0, height: '17px', width: '20px', borderRadius: 0 } }}
                                    {...props}
                                >
                                    <img alt="" loading="lazy" src={`https://flagcdn.com/w20/de.png`} />
                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <Typography>{intl.formatMessage({ id: 'languages_german' })}</Typography>
                                        <Typography sx={{ color: '#9e9e9e', fontSize: '12px' }}>
                                            {intl.formatMessage({ id: `languages_${option}` })}
                                        </Typography>
                                    </span>
                                </Box>
                            );
                    }
                }}
                size="small"
                sx={{
                    width: 160,
                    '.MuiAutocomplete-inputRoot': {
                        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                    },
                    '.MuiSvgIcon-root': {
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark
                    }
                }}
                value={userLanguage ?? ''}
            />
        </Box>
    );
};

export default LanguageGroupSection;
