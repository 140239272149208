import { InstallationPoint } from 'AppContext/Enterprise/Regions/Models/InstallationPointsModel';

export type IEnterprise = {
    id: number;
    name: string;
    description: string;
};

export type ICompany = {
    id: number;
    name: string;
    country: string;
    description: string;
    website: string | null;
    image: string | null;
    enterpriseId: number;
    gpsPoints: Array<InstallationPoint>;
    regions: Array<IRegionCompany>;
};

export type IRegionCompany = {
    id: number;
    name: string;
};

export enum CompanyModelKeys {
    id = 'id',
    name = 'name',
    country = 'country',
    description = 'description',
    website = 'website',
    image = 'image',
    enterpriseId = 'enterpriseId'
}
