export const COLORS = {
    // RED
    ENEIDA_RED: '#CC4444',
    ENEIDA_ALTERNATIVE_RED: '#FFA8A8',
    ENEIDA_SIMPLE_LIGHT_RED: '#fbdfd6',
    // GREEN
    ENEIDA_GREEN: '#2e7d32',
    ENEIDA_ALTERNATIVE_GREEN: '#00c853',
    ENEIDA_SIMPLE_LIGHT_GREEN: '#ddffe6',
    // CYAN
    ENEIDA_DARK_CYAN: '#0097a7',
    ENEIDA_ALTERNATIVE_DARK_CYAN: '#009688',
    // CYAN
    ENEIDA_MODERATE_VIOLET: '#7e57c2',
    // BLUE
    ENEIDA_BLUE: '#405a73',
    ENEIDA_ALTERNATIVE_BLUE: '#2d3f51',
    ENEIDA_SIMPLE_BLUE: '#6CCBFF',
    ENEIDA_SIMPLE_LIGHT_BLUE: '#e3f2ff',
    ENEIDA_SOLID_BLUE: '#0168A0',
    ENEIDA_MODERATE_BLUE: '#5c6bc0',
    ENEIDA_VIVID_BLUE: '#1e88e5',
    // YELLOW
    ENEIDA_YELLOW: '#eeba17',
    ENEIDA_ALTERNATIVE_YELLOW: '#FFF59A',
    ENEIDA_LIGHT_YELLOW: '#f5df9f',
    ENEIDA_STRONG_YELLOW: '#f7bb05',
    ENEIDA_SIMPLE_YELLOW: '#FFF8D2',
    ENEIDA_DARK_YELLOW: '#A49513',
    // GREY
    ENEIDA_GREY: '#c7c7c7',
    ENEIDA_SIMPLE_GREY: '#F0F0F0',
    ENEIDA_ALTERNATIVE_GREY: '#7D7D7D'
};
