import { Navigate } from 'react-router-dom';

import KeycloakLogin from '../../views/pages/KeycloakLogin';

const LoginRoutes = {
    path: '/',
    element: <KeycloakLogin />,
    children: [
        {
            path: '/',
            element: <KeycloakLogin />
        },
        {
            path: '/login',
            element: <KeycloakLogin />
        },
        {
            path: '/*',
            element: <Navigate replace to="/login" />
        }
    ]
};

export default LoginRoutes;
