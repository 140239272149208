import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef } from 'react';

const QrCodeView = ({ qr }: { qr: QRCodeStyling }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            qr.append(ref.current);
        }
    }, [qr]);

    return <div ref={ref} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }} />;
};

export default QrCodeView;
