import './styles/app.scss';

import SnackbarAlert from '_global/Components/base/extended/SnackbarAlert';
import themes from '_global/Resources/themes';
import AppRoutes from '_global/Routes';
import { getDataV2 } from '_global/Services/api/networkRequester';
import { customizationAtom } from '_global/Services/store/customization';
import { gridsPaginationAtom, removeAllGrids } from '_global/Utils/GridPagination';
import { flagShouldUpdateAtom, lastUserSettingsAtom, stateToastAtom, useConfigurationAtom } from '_global/Utils/hooks/jotai';
import { removeAllTabs } from '_global/Utils/Tabs';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useKeycloak } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import { countryAtomCompany } from 'AppContext/Enterprise/Companies/Services/Internal/CompaniesJotai';
import NavigationScroll from 'controllers/layout/NavigationScroll';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import { Configuration } from 'logic/configuration/Configuration';
import SettingsFetcher from 'logic/configuration/SettingsFetcher';
import { ICountry } from 'models/layout/Country';
import { IRemoteUserSettings } from 'models/settings/RemoteUserSettings';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';

import ErrorComponent from './_global/Components/base/ErrorComponent';
import config from './config';

const ChildrenApp = () => {
    const customization = useAtomValue(customizationAtom);
    const configuration = useAtomValue(useConfigurationAtom);
    const [configurationShown, setConfigurationShown] = useState(false);
    const { keycloak, initialized } = useKeycloak();
    const [lastUserSettings, setLastUserSettings] = useAtom(lastUserSettingsAtom);
    const [shouldUpdate, setShouldUpdate] = useAtom(flagShouldUpdateAtom);
    const [{ message, type, open }, setStateToast] = useAtom(stateToastAtom);
    const setGridPagination = useSetAtom(gridsPaginationAtom);
    const setCountries = useSetAtom(countryAtomCompany);

    const handleCloseToast = () => {
        setStateToast((old) => ({ ...old, open: false }));
    };

    useEffect(() => {
        if (configuration !== undefined && configuration !== null && !configurationShown) {
            console.warn(`Using configuration: ${configuration.Name}`);
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            console.warn(`Version: ${import.meta.env.VITE_VERSION ?? import.meta.env.PACKAGE_VERSION}`);

            setConfigurationShown(true);
        }
    }, [configuration]);

    async function fetchGlobal() {
        if (configuration && keycloak.token) {
            return await SettingsFetcher.getUserSettings(keycloak.token, configuration);
        }
    }

    async function submitGlobal(token: string, localConfig: Configuration, lastUserSettings: IRemoteUserSettings) {
        await SettingsFetcher.postUserSettings({ ...lastUserSettings, idToken: token }, localConfig);
    }

    useEffect(() => {
        if (keycloak.token && keycloak.token !== '' && configuration !== undefined && lastUserSettings === undefined) {
            fetchGlobal()
                .then((result) => {
                    if (!result) {
                        return;
                    }
                    setLastUserSettings(result);
                    setShouldUpdate(JSON.parse(JSON.stringify(result)) as IRemoteUserSettings);
                })
                .catch(console.error);
        }
    }, [keycloak.token, configuration, lastUserSettings]);

    useEffect(() => {
        if (
            keycloak.token &&
            keycloak.token !== '' &&
            configuration &&
            lastUserSettings !== undefined &&
            lastUserSettings.accessGroups.length &&
            shouldUpdate !== undefined &&
            !_.isEqual(lastUserSettings.userSettings, shouldUpdate?.userSettings)
        ) {
            submitGlobal(keycloak.token, configuration, lastUserSettings)
                .then(() => {
                    setShouldUpdate(JSON.parse(JSON.stringify(lastUserSettings)) as IRemoteUserSettings);
                })
                .catch(console.error);
        }
    }, [lastUserSettings, shouldUpdate]);

    useEffect(() => {
        if (keycloak && initialized) {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            keycloak.onAuthRefreshError = async () => {
                try {
                    removeAllGrids({ setGridPagination });
                    removeAllTabs();

                    await keycloak.logout({
                        redirectUri:
                            !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                                ? `${window.location.origin}/login`
                                : `${configuration?.BaseUrl ?? ''}/login`
                    });
                } catch (err) {
                    console.error(err);
                }
            };

            //Update Token minValidity = Keycloak Config Access Token Lifespan (in Seconds)
            keycloak.onTokenExpired = () => {
                keycloak.updateToken(900).catch((e) => console.error(e));
            };
        }

        return () => {
            if (keycloak) {
                keycloak.onAuthRefreshError = () => {};
                keycloak.onTokenExpired = () => {};
            }
        };
    }, [initialized, keycloak]);

    useEffect(() => {
        const endpoint = `${configuration?.BaseUrl ?? ''}/layout/api/StaticProvider/GetAllCountries`;
        getDataV2<ICountry[]>(endpoint)
            .then((data) => {
                data?.sort(function (firstCountry, secondCountry) {
                    return firstCountry.name.common.localeCompare(secondCountry.name.common);
                });
                setCountries(data);
            })
            .catch(() => {
                setCountries([]);
            });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <SnackbarAlert message={message} onClose={handleCloseToast} open={open} type={type} />
                <CssBaseline />
                <BrowserRouter basename={config.basename}>
                    <Sentry.ErrorBoundary fallback={<ErrorComponent />} showDialog={false}>
                        <NavigationScroll>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DndProvider backend={HTML5Backend}>
                                    <AppRoutes />
                                </DndProvider>
                            </LocalizationProvider>
                        </NavigationScroll>
                    </Sentry.ErrorBoundary>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ChildrenApp;
