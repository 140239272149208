const tabStorageKey = 'sessionTabs';

export function useDynamicState<T>(storageKey: string): [() => T | undefined, (state: T) => void, () => void] {
    const getState = () => {
        try {
            const serializedState = sessionStorage.getItem(storageKey);
            if (serializedState) {
                return JSON.parse(serializedState) as T;
            }
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    const saveState = (state: T) => {
        try {
            const serializedState = JSON.stringify(state);
            sessionStorage.setItem(storageKey, serializedState);
        } catch (e) {
            console.error(e);
        }
    };

    const cleanState = () => {
        try {
            sessionStorage.removeItem(storageKey);
        } catch (e) {
            console.error(e);
        }
    };

    return [getState, saveState, cleanState];
}

export function useSessionTabs(): string[] | undefined {
    try {
        const serializedState = sessionStorage.getItem(tabStorageKey);
        if (serializedState) {
            return JSON.parse(serializedState) as string[];
        }
    } catch (e) {
        console.error(e);
    }
    return undefined;
}

export function setSessionTabs(newSessionTabs: (string | undefined)[]): void {
    sessionStorage.setItem(tabStorageKey, JSON.stringify(newSessionTabs));
}

export function clearSessionTabs(): void {
    sessionStorage.removeItem(tabStorageKey);
}
