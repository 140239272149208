import DeepgridLoader from '_global/Components/base/DeepgridLoader';
import Loader from '_global/Components/base/Loader';
import AlertModalForResponse from '_global/Components/base/modals/AlertModalForResponse';
import { drawerWidth } from '_global/Services/store/constant';
import { lastUserSettingsAtom, reRenderMapAtomDashboard, stateModalAtom } from '_global/Utils/hooks/jotai';
import { AppBar, Box, Toolbar, useMediaQuery } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';

interface IMainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: IMainStyleProps) => ({
    ...theme.typography.mainContent,
    height: '100%',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        padding: '10px',
        marginRight: 0,
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 10),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '10px',
        marginRight: 0,
        [theme.breakpoints.down('md')]: {
            marginLeft: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //
export const showSpecialLoadingAtom = atom(false);
export const showLoadingAtom = atom(false);

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const [leftDrawerOpened, setLeftDrawerOpened] = useState(!matchDownMd);

    //Interceptor Loading Component
    const showLoading = useAtomValue(showLoadingAtom);
    const showSpecialLoading = useAtomValue(showSpecialLoadingAtom);
    const [renderMap, setRenderMap] = useAtom(reRenderMapAtomDashboard);
    const [{ responseContent, responseTitle, openModal, type, onCancel, onOk, onYes, onNo }, setStateModal] = useAtom(stateModalAtom);

    const config = useAtomValue(lastUserSettingsAtom);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (config?.accessGroups && config?.accessGroups.length <= 0) {
            navigate('/error/accessgroup', { replace: true });
        }
    }, [window.location.pathname, config?.accessGroups]);

    const handleCloseModal = () => {
        setStateModal((old) => ({ ...old, openModal: false }));
    };

    return (
        <>
            {showLoading && <Loader />}
            <DeepgridLoader open={showSpecialLoading} />

            <Box sx={{ display: 'flex', margin: 0, overflow: 'hidden', height: '100vh' }}>
                {/* header */}
                <AppBar
                    color="inherit"
                    elevation={0}
                    enableColorOnDark
                    position="fixed"
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar>
                        <Header
                            handleLeftDrawerToggle={() => {
                                setRenderMap(!renderMap);
                                setLeftDrawerOpened(!leftDrawerOpened);
                            }}
                        />
                    </Toolbar>
                </AppBar>
                {/* drawer */}
                {config?.roles?.[0]?.hasWebAccess && (
                    <Sidebar
                        drawerOpen={leftDrawerOpened}
                        drawerToggle={() => {
                            setLeftDrawerOpened(!leftDrawerOpened);
                        }}
                    />
                )}
                {/* main content */}

                <AlertModalForResponse
                    isOpen={openModal}
                    message={responseContent}
                    onCancel={onCancel}
                    onNo={onNo}
                    onOk={onOk ?? handleCloseModal}
                    onYes={onYes}
                    title={responseTitle}
                    type={type}
                />

                <Main open={leftDrawerOpened} theme={theme}>
                    <Outlet />
                </Main>
            </Box>
        </>
    );
};

export default MainLayout;
