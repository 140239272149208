export enum LanguagesEnum {
    pt_PT = 'pt-PT',
    en_GB = 'en-GB',
    en_NZ = 'en-NZ',
    en_AU = 'en-AU',
    de_DE = 'de-DE'
}
enum CountryTranslationEnum {
    PT = 'por',
    EN = 'cym',
    DE = 'deu'
}

export default function LocaleLanguageConverter(language: string): string {
    const aux = Object.values(LanguagesEnum).find((x) => x === language) ? language : LanguagesEnum.en_GB;
    const split = aux.split('-');
    return split[1].toLowerCase() !== split[0].toLowerCase() ? language.toLocaleLowerCase() : split[0].toLocaleLowerCase();
}

export function CountryLanguageConverter(language: string): string {
    return language === LanguagesEnum.pt_PT
        ? CountryTranslationEnum.PT
        : language === LanguagesEnum.de_DE
          ? CountryTranslationEnum.DE
          : CountryTranslationEnum.EN;
}
