import { IFilter } from '_global/Components/base/filter/FilterTypes';
import { atom } from 'jotai';
import Mode from 'models/enums/ModeEnum';
import { ICountry } from 'models/layout/Country';
import { IValidations } from 'models/validation/Validation';

import { defaultCompanyValidation } from './CompaniesValidator';

const handleRefreshCompany = atom('');
const handleRefreshCompanyRegions = atom('');
const modeAtomCompany = atom<Mode>(Mode.LIST);
const countryAtomCompany = atom<ICountry[]>([]);
const validationAtomCompany = atom<IValidations[]>(defaultCompanyValidation);
const filterAtomCompany = atom<IFilter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomCompany = atom<string>(Mode.LIST);
const selectAllCompaniesAtom = atom<
    {
        index: number;
        active: boolean;
        allSelected: boolean;
        selectedItems: boolean | Record<string, Record<string, boolean>>;
        selectedIds: number[];
    }[]
>([{ index: 1, active: true, allSelected: false, selectedItems: {}, selectedIds: [] }]);
const selectedCompaniesAtom = atom<{ all: boolean; filterQuery: string | undefined; ids: number[] }>({
    all: false,
    filterQuery: undefined,
    ids: []
});
const rowCompanyDeleteIdAtom = atom<number | undefined>(undefined);

export {
    countryAtomCompany,
    filterAtomCompany,
    handleRefreshCompany,
    handleRefreshCompanyRegions,
    modeAtomCompany,
    rowCompanyDeleteIdAtom,
    selectAllCompaniesAtom,
    selectedCompaniesAtom,
    tabAtomCompany,
    validationAtomCompany
};
