import { customizationAtom } from '_global/Services/store/customization';
import { userLanguageAtom } from '_global/Utils/hooks/jotai';
import { useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';

export const loadLocaleData = (locale: string) => {
    switch (locale) {
        case 'nl':
            return import('_global/Resources/locales/nl/compiled.json');
        case 'pt-PT':
            return import('_global/Resources/locales/pt-PT/compiled.json');
        case 'de-DE':
            return import('_global/Resources/locales/de/compiled.json');
        case 'en-NZ':
            return import('_global/Resources/locales/en-NZ/compiled.json');
        case 'en-AU':
            return import('_global/Resources/locales/en-AU/compiled.json');
        default:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return import('_global/Resources/locales/en-GB/compiled.json');
    }
};

export const supportedLocales = ['en-GB', 'en-US', 'en-AU', 'en-NZ', 'de-DE', 'pt-PT', 'nl'];

export function flattenMessages(nestedMessages: Record<string, string> | Record<string, MessageFormatElement[]>, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}_${key}` : key;
        if (typeof value === 'string') {
            messages[prefixedKey] = value;
        } else {
            Object.assign(messages, flattenMessages(value, prefixedKey));
        }

        return messages;
    }, {});
}

export interface ILocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: ILocalsProps) => {
    const customization = useAtomValue(customizationAtom);
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();
    const [local, setLocale] = useState('en-GB');
    const language = useAtomValue(userLanguageAtom);

    useEffect(() => {
        if (language && language !== '') {
            setLocale(language);
            loadLocaleData(language)
                .then((d) => {
                    if (d.default === undefined) {
                        return;
                    }
                    const messages = flattenMessages(
                        d.default as unknown as Record<string, string> | Record<string, MessageFormatElement[]>
                    );
                    setMessages(messages);
                })
                .catch(console.error);
        } else {
            loadLocaleData(customization.locale)
                .then((d) => {
                    if (d.default === undefined) {
                        return;
                    }
                    const messages = flattenMessages(
                        d.default as unknown as Record<string, string> | Record<string, MessageFormatElement[]>
                    );
                    setMessages(messages);
                })
                .catch(console.error);
        }
    }, [language, customization.locale]);

    return (
        <>
            {messages && (
                <IntlProvider defaultLocale="en-GB" locale={local} messages={messages} onError={(e) => e}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
