import config from 'config';
import { atom } from 'jotai';
import { ICustomizationStateProps } from 'models';

export const initialState: ICustomizationStateProps = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    navType: config.theme,
    presetColor: config.presetColor,
    locale: config.i18n,
    rtlLayout: config.rtlLayout,
    opened: true
};

export const customizationAtom = atom<ICustomizationStateProps>(initialState);

export const setCustomization = (
    newCustomization: ICustomizationStateProps,
    setCustomization: (value: ICustomizationStateProps) => void
) => {
    setCustomization(newCustomization);
};
